<template>
  <div class="pageview">
    <div style="height:10px;background-color:#f9f9f9;"></div>
    <div style="width:95%;margin:auto;padding-top:7px;">
      <van-list :loading="loading"
        :finished="finished"
        :offset="130"
        finished-text="没有更多了">

        <div class="information">
          <div class="center"
            style="padding:0;"
            v-for="item in list"
            :key="item.index">
            <div>
              <van-card style="padding: 10px;background-color: white; box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);">
                <template #thumb>
                  <div class="van-card__thumb">
                    <img v-if="item.Poster == ''"
                      style="width:100%;height:100%;"
                      src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
                    <img v-else
                      style="width:100%;height:100%;"
                      :src="item.PosterUrl.split(',')[0]" />
                  </div>

                </template>
                <template #title>
                  <div class="centerTitle van-ellipsis--l1"
                    @click="gotoUrl(item.EateryId)"
                    style="width: 75%;padding-bottom: 5px;">{{ item.Title }}</div>
                  <div class="county"
                    style="top:0;">
                    <span v-if="item.Status == '1'"
                      style="color:#E6A23C;">{{ item.StatusDesc }}</span>
                    <span v-if="item.Status == '2'"
                      style="color:#f2d80a;">{{ item.StatusDesc }}</span>
                    <span v-if="item.Status == '3'"
                      style="color:#67c23a;">{{ item.StatusDesc }}</span>
                  </div>
                </template>

                <template #tags>
                  <!-- <van-tag plain
                    type="warning">{{item.SortName}}</van-tag> -->

                  <div style="padding-bottom: 5px;font-size:14px;">
                    限额：{{item.Quota}} 名
                  </div>
                  <!-- <div style="padding: 5px 0;font-size:14px;">
                    <van-icon name="phone"
                      color="#C8CAD8" /> {{item.TouchTel}}
                  </div> -->
                  <!-- <div style="width: 75%;"
                    class="van-multi-ellipsis--l2">
                    <van-icon name="location"
                      color="#C8CAD8" /> {{item.Address}}
                  </div> -->
                  <div class="van-ellipsis--l2"
                    style="width: 75%;padding-bottom: 5px;font-size:14px;"> {{ item.IssTime }}</div>
                  <div class="county">
                    <van-button type="default"
                      round
                      style="width:70px;"
                      @click="shopPenple(item.PromotionId)"
                      size="mini">报名列表</van-button>
                    <van-button type="info"
                      round
                      style="width:60px;"
                      @click="shopUpdata(item.PromotionId)"
                      size="mini">编辑</van-button>
                    <van-button type="danger"
                      round
                      style="width:60px;"
                      @click="deletGoodsType(item.PromotionId)"
                      size="mini">删除</van-button>
                    <!-- <img src="https://xczxapi.zhsq.cloud//upload/images/2022/6/b10693b0-c37.png"
                      alt=""
                      style="width: 24px;"> -->
                  </div>
                </template>
              </van-card>
            </div>
          </div>
        </div>

      </van-list>
      <div>
        <div style="width: 90%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 100%"
            @click="addPromotion(1)"
            color="#617bfa">新增促销活动</van-button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetAppletOrganList } from "@/api/index";
import { WxGetMyPromotionPage, WxSaveGoodsSort, WxDeleteGoods, WxDeleteMyPromotion } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data () {
    return {
      activeNames: {},
      list: [],
      listfrom: {
        page: 1,
        limit: 10,
        openID: getOpenId(),
        shopId:this.$route.params.id,
      },
      shopId: this.$route.params.id,
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
    }
  },
  created () {
    console.log(this.$route.query["openid"]);
    console.log(this.shopId);
    this.getGoodsSortList()
  },
  methods: {
    // 删除
    deletGoodsType (row) {
      Dialog.confirm({
        title: '提示',
        message:
          '确定要删除该商品吗',
      })
        .then(() => {
          // 确认
          this.getdeletGoodsType(row)
        })
        .catch(() => {
          // 取消
          console.log(111);
        });
    },
    getdeletGoodsType: function (row) {
      console.log(row);
      WxDeleteMyPromotion({ openID: getOpenId(), PromotionId: row })
        .then((res) => {
          if (res.data.code == 0) {
            Toast.success("删除成功");
            this.list = []
            this.getGoodsSortList()
          }
        })

    },
    // 获取促销活动列表-商家
    getGoodsSortList () {

      WxGetMyPromotionPage(this.listfrom).then((res) => {
        // this.jqTjList = res.data.data;
        // 加载状态结束
        let rows = res.data.data; //请求返回当页的列表
        this.loading = false;

        if (res.data.code == 0) {
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= res.data.count) {
            this.finished = true;
          }
        }
      })
    },
    // 详情
    gotoUrl (path) {
      this.$router.push('/travel/deliciousFood/foodXq/' + path);
    },
    // 跳转小程序导航
    gotominiProgram (details) {
      console.log(details);
      if (details.Coordinate == '') {
        Toast.fail('暂无地理位置');
      } else {
        this.nameCc = {
          Navigation: '导航',
          Coordinate: details.Coordinate
        }
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url: "../public/openminip?name=" + JSON.stringify(this.nameCc),
          });
        } else {
          alert("请在小程序中打开");
        }
      }

    },
    //新增促销活动
    addPromotion (row) {
      this.$router.push('/shop/myShop/Promotion/promotionAdd/' + 1 + '/' + row+'/'+this.shopId);
      // this.$router.push({
      //   name: "addGoods",
      //   query: { bfaId: this.itemmark, add: 1 },
      // });
    },
    // 编辑
    shopUpdata (row) {
      this.$router.push('/shop/myShop/Promotion/promotionAdd/' + 2 + '/' + row+'/'+this.shopId);
      // this.$router.push({
      //   name: "addGoods",
      //   query: { bfaId: this.itemmark, add: 1 },
      // });
    },
    // 报名列表
    shopPenple (row) {
      this.$router.push('/shop/myShop/Promotion/PromotionPenple/' + row);
    },
  }
}
</script>

<style scoped>
.pageview {
  /* height: 100%; */
  /* background-color: #f8f8f8; */
  min-height: 100%;
}
.sousuo {
  height: 54px;
  background: white;
  line-height: 54px;
  padding-left: 15%;
  margin: auto;
  color: red;
}
.waterfall {
  /* width: 95%; */
  margin: 0 auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.address {
  margin: 10px 0;
  color: #92600d;
  font-size: 14px;
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.count {
  color: #000000;
  font-size: 17px;
}
.money {
  margin: 10px 0;
  padding-bottom: 10px;
  color: #fe5b47;
  font-size: 18px;
}
/* .center {
  border-bottom: 1px solid #e8e8e8;
}
.center:last-child {
  border-bottom: 0px solid #e8e8e8;
  padding: 10px;
} */
.tagClass {
  margin: 10px 0;
}
.van-card__thumb {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  width: 95px;
  height: 95px;
  margin-right: 12px;
}
/deep/.van-tab {
  font-size: 16px;
}
.county {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 5px;
}
</style>